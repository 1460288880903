import { navigate as gatsbyNavigate } from 'gatsby'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import { TEST_ID } from '~/constants/cypress'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import type {
  CancelPanelProps,
  CancelPanelOptions,
} from '~/components/CancelFlowPanels/CancelFlowPanel.types'
import GenericCancelPanel from '../../GenericCancelPanel/GenericCancelPanel.ui'

const PanelSelectCustomCuts: React.FC<CancelPanelProps> = ({ panel }) => {
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext)

  const modalOptions: Array<CancelPanelOptions> = [
    {
      cypressId: TEST_ID.CANCEL_FLOW_SAVE,
      text: 'Select my own cuts',
      targetSaveComponent: 'Customize My Cuts',
      clickHandler: () => {
        gatsbyNavigate('/account/customize-your-box?source=cancelflow')
        trackCancellationSave(
          CANCEL_SAVE_ACTIONS.VISIT_CUSTOM_CUTS_FROM_CANCEL_FLOW,
          getHistoryEntry(1).pathname,
          getHistoryEntry(2).state.text
        )
      },
    },
  ]

  return <GenericCancelPanel modalOptions={modalOptions} panel={panel} />
}

export default PanelSelectCustomCuts
